import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ExampleBox = makeShortcode("ExampleBox");
const StandardAlertExample = makeShortcode("StandardAlertExample");
const StandardAlertDismissableExample = makeShortcode("StandardAlertDismissableExample");
const PrimaryAlertExampleBundle = makeShortcode("PrimaryAlertExampleBundle");
const SecondaryAlertExampleBundle = makeShortcode("SecondaryAlertExampleBundle");
const InformationAlertExample = makeShortcode("InformationAlertExample");
const ErrorAlertExample = makeShortcode("ErrorAlertExample");
const WarningAlertExample = makeShortcode("WarningAlertExample");
const SuccessAlertExample = makeShortcode("SuccessAlertExample");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-alerts"
            }}>{`How to use alerts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-alerts"
            }}>{`Types of alerts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#styles-of-alerts"
            }}>{`Styles of alerts`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <h2 {...{
      "id": "how-to-use-alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-alerts",
        "aria-label": "how to use alerts permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use alerts`}</h2>
    <p>{`Alerts communicate important messages about the status of a system or service to users. They are categorised as success, information, warning or error messages, with a colour associated to each state. You can read more about when to use which alert in the copy guidance section.`}</p>
    <p>{`When using alerts:`}</p>
    <ul>
      <li parentName="ul">{`Keep messaging short and simple`}</li>
      <li parentName="ul">{`Consider how they might draw focus from other elements of an experience, as they are disruptive`}</li>
      <li parentName="ul">{`Use ARIA to announce the alert to screen reader users when it appears`}</li>
      <li parentName="ul">{`If the alert can be dismissed, make sure that focus is moved to the alert when it appears and then returned to the previously focused element when closed`}</li>
    </ul>
    <p>{`Alerts are for messages of medium importance. For highly important messages, consider a modal instead.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "types-of-alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-alerts",
        "aria-label": "types of alerts permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of alerts`}</h2>
    <h3>{`Standard`}</h3>
    <p>{`Standard alerts can’t be dismissed, and will remain on screen as long as the user is on it.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <StandardAlertExample mdxType="StandardAlertExample" />
    </ExampleBox>
    <h3>{`Dismissible`}</h3>
    <p>{`Dismissible alerts include a close action so that users can choose to dismiss them. The close icon has the accessible name ‘close’.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <StandardAlertDismissableExample mdxType="StandardAlertDismissableExample" />
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "styles-of-alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styles-of-alerts",
        "aria-label": "styles of alerts permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Styles of alerts`}</h2>
    <p>{`Alerts can be used with a primary or secondary styling. You can use the different styles to create heirachy or emphasis.`}</p>
    <h3>{`Primary`}</h3>
    <p>{`Use primary alerts for when you need to get the user’s attention and interrupt what they’re doing to tell them something significant. `}</p>
    <p>{`When showing multiple alerts, you can use the primary style to highlight the alert of the most importance.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <PrimaryAlertExampleBundle mdxType="PrimaryAlertExampleBundle" />
    </ExampleBox>
    <h3>{`Secondary`}</h3>
    <p>{`Use secondary alerts for more gentle messaging, such as a reminder of something important you need to highlight to the user, but isn’t as urgent as the primary style alerts.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <SecondaryAlertExampleBundle mdxType="SecondaryAlertExampleBundle" />
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <p>{`Alerts can be organised into four different semantic categories: information, error, warning and success. Each of these categories is denoted by a different colour.`}</p>
    <p>{`However, remember that colour alone isn’t enough to communicate effectively with all users. All of the information the user needs must be contained within the content itself.`}</p>
    <p>{`When writing content for alerts:`}</p>
    <ul>
      <li parentName="ul">{`Keep the message concise and the language simple`}</li>
      <li parentName="ul">{`Be specific rather than generic`}</li>
      <li parentName="ul">{`Include a next action where possible`}</li>
    </ul>
    <h3>{`Information messages`}</h3>
    <p>{`Information messages are associated with the semantic colour blue. They’re used for messages that might help a user to complete their task, but don’t require any action from the user in order to progress.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <InformationAlertExample mdxType="InformationAlertExample" />
    </ExampleBox>
    <h3>{`Error messages`}</h3>
    <p>{`Error messages are associated with the semantic colour red. They’re for communicating issues with the system or service to the user, and are typically used in situations where the user’s not able to progress.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <ErrorAlertExample mdxType="ErrorAlertExample" />
    </ExampleBox>
    <h3>{`Warning messages`}</h3>
    <p>{`Warning messages are associated with the semantic colour yellow. Like an error message, they’re for communicating issues with the system or service, but with a lower level of emphasis. For example, the user may still be able to progress.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <WarningAlertExample mdxType="WarningAlertExample" />
    </ExampleBox>
    <h3>{`Success messages`}</h3>
    <p>{`Success messages are associated with the semantic colour green. They’re for acknowledging that an action that a user has taken has been successfully submitted to the system.`}</p>
    <ExampleBox mdxType="ExampleBox">
  <SuccessAlertExample mdxType="SuccessAlertExample" />
    </ExampleBox>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using alerts or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      